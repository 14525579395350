.noteItemA{
    text-align: left;
    color: black;
    background-color: #250777;
    border-radius: 25% 10%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1vw; 
}

.noteItemB{
    text-align: left;
    color: black;
    background-color: purple;
    border-radius: 25% 10%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1vw; 
}

.noteItemC{
    text-align: left;
    color: black;
    background-color: rgb(71, 71, 238);
    border-radius: 25% 10%;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1vw; 
}

.noteGap{
    padding: 10px;
}

.header{
    text-align: center;
    font: bolder;
    font-size: 1vw;
    outline: auto;
    outline-width: 3px;
    outline-color: black;
    background-color: white;
    color: black;
    border-radius: 10% 25%;
}