.chart{
    outline-color: red;
    border: none !important;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
}

.asite{
    color: red;
}

.bsite{
    color: blue;
}

.csite{
    color : #370D32;
}