.reactiveCard {
    background-color: white;
    width: 50%;
}
.comingSoon{
    color:White;
}
.card{
    text-align: center;
    justify-content: center;
    float: center;
}