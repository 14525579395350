.inlineGrid{
    display : flex;
    flex-direction : row;
    }

.element{
    padding-left: 10px;
    padding-right: 10px;
}

.coordinatesBox{
    padding: 1%;
    border: 1px solid white;
}