.resetButton{
    font-size: 2vw;
}

.whiteBoard{
    background-size:cover;
    background: none !important;
    outline: auto;
    color: purple;
    height: 40vw !important;
    width: 38vw !important;
    
}
@media screen and  (max-width: 700px) {
    .whiteBoard{
        background-size:cover;
        background: none !important;
        outline: auto;
        color: purple;
        height: 99vw !important;
        width: 99vw !important;
        
    }

}
.scoreBoard{
 display: inline-flex;   
}
.attacker{
    color: white;
}
.defender{
    color: blueviolet;
}

.Haven{
    color: white;
    background-repeat: no-repeat;
    background-image: url("./WhiteBoardResources/haven_callouts.jpg");
    background-size:contain; 
}

.Breeze{
    color: white;
    background-repeat: no-repeat;
    background-image: url("./WhiteBoardResources/breeze.jpg");
    background-size:contain;
}

.Ascent{
    color: white;
    background-image: url("./WhiteBoardResources/ascent.jpg");
    background-size:contain;
    background-repeat: no-repeat;
    
    

}
.Split{
    color: white;
    background-image: url("./WhiteBoardResources/split.jpg");
    background-size:contain;
    background-repeat: no-repeat;
}

.Bind{
    color: white;
    background-image: url("./WhiteBoardResources/bind.jpg");
    background-size:contain;
    background-repeat: no-repeat;

}
.Icebox{
    color: white;
    background-image: url("./WhiteBoardResources/icebox.jpg");
    background-size:contain;
    background-repeat: no-repeat;

}


