.center {
    text-align: center;

    border: 3px solid green;
   padding-left: 10%;
   padding-right: 10%;
}

.left {
    text-align: left;
}

.GroundBox{
    width: 1.5vw;
    height: 1vw;
    border: 1px solid transparent;
    background-color: #420bce;
    opacity: 50;
}


.section1{
    width: 1.5vw;
    height: 1vw;
    border: 1px solid transparent;
    background-color: #420bce;
    opacity: 50;
}

.PeekWall{

    width: 1.5vw;
    height: 1vw;
    border: 1px solid transparent;
    background-color: blue;
    opacity: 50;

}

.SpaceBox{
    width: 1.5vw;
    height: 1vw;
    border: 1px solid transparent;
   
    opacity: 50;
}

.border{
     width: 1.5vw;
    height: 1vw;
    border: 1px solid transparent;
    background-color: black;
    opacity: 50;
}

/* responsive, form small screens, use 13px font size */
@media screen and  (max-width: 900px) {

    .center {
        text-align: center;
        
        border: 3px solid green;
       padding-left: 0%;
       padding-right: 0%;
      }
    
    .GroundBox{
        width: 2vw;
        height: 2vw;
        border: 1px solid transparent;
        background-color: #420bce;
        opacity: 50;
    }

    .section1{
        width: 2vw;
        height: 2vw;
        border: 1px solid transparent;
        background-color: red;
        opacity: 50;
    }
    
    
    .PeekWall{
    
        width: 2vw;
        height: 2vw;
        border: 1px solid transparent;
        background-color: rgb(14, 14, 131);
        opacity: 50;
    
    }
    
    .SpaceBox{
        width: 2vw;
        height: 2vw;
        border: 1px solid transparent;
        background-color: black;
        opacity: 50;
    }
    
    .border{
        width: 2vw;
        height: 2vw;
        border: 1px solid transparent;
        background-color: black;
        opacity: 50;
    }
}







