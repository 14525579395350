.Grid-container {
  display: grid;
  grid-template-columns: 35% 40% 20%;
  grid-gap: 10px;
  padding: 10px;
}


.Grid-container > div {
 
  font-size: 30px;
}
  
  .Inputform {
    color: white;
    width: 100%;
  }

  .CharacterCard{
    text-align: left;
  }


  .Analytics {
    color: red;

  }
  .AnalyticsHeader{
    grid-template-columns: auto auto;
  }

  .roundSummary{
    text-align: left;
  }


  .roundStyles{
    outline: solid;
    outline-color: red;
    outline-width: 3px;
    text-align: left;
  }
  .mobile-map{
    display: none;
  }

  @media screen and  (max-width: 700px) {
    
    .Grid-container {
      display: grid;
      grid-template-columns: 0% 100% 0%;
      grid-gap: 10px;
      padding: 10px;
    }
    .mobile-map{
      display: grid;
    }

    .Grid-container > div  {
      font-size: 10px;
    }

    .input{
      display: none;
    }
    .whiteboard{
      width: 100vw;
      height: 100vw;
    }
    .Inputform {

      color: white;
      width: 100%;
      
    }
  
    .CharacterCard{
      display: none;
      text-align: left;
      
    }
  
  
    .Analytics {
      display: none;
      color: red;
  
    }
    .AnalyticsHeader{
      display: none;
      grid-template-columns: auto auto;
    }
  
    .roundSummary{
      display: none;
      text-align: left;
    }
  
  
    .roundStyles{
      display: none;
      outline: solid;
      outline-color: red;
      outline-width: 3px;
      text-align: left;
    }
  }