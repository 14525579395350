.histogramHeader{
  font-size: 2vw;
}
.padding{
    padding: 10px;
}
.chart {
    max-height: 350px;
    max-width: 350px;
    color:white !important;
  }

.row {
    margin:0 !important;
  }